import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PaginaContent from "./PaginaContent";
import RandomNotFoundPage from "../pages/RandomNotFoundPage";

const PaginaDetalhePage = ({ fluxo, loadFluxo, setFluxo, starryCanvasRef }) => {
    const { fluxoSlug, paginaSlug } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const savedFluxo = localStorage.getItem(`fluxo_${fluxoSlug}`);
        if (savedFluxo) {
            setFluxo(JSON.parse(savedFluxo));
        } else {
            loadFluxo(fluxoSlug);
        }
    }, [fluxoSlug, setFluxo, loadFluxo]);

    let pagina;

    if (fluxo) {
        pagina = fluxo.paginas.find(p => p.slug === paginaSlug);
    }

    if (!fluxo || (fluxo && !pagina)) {
        return <RandomNotFoundPage />;
    }

    const handleButtonClick = (nextPaginaSlug, newInformacao) => {
        navigate(`/fluxo/${fluxoSlug}/${nextPaginaSlug}`, newInformacao);
    };

    return (
        <div className="pagina-detalhe">
            <PaginaContent pagina={pagina} onButtonClick={handleButtonClick} isHomePage={false} starryCanvasRef={starryCanvasRef}/>
        </div>
    );
};

export default PaginaDetalhePage;
