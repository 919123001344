import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Card from "./Card";
import { getRandomInt } from "../utils/utils";
import "./PaginaContent.css";
import Dica from "./Dica";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchChangelog } from "../api/api";
import MascoteFibra from "./MascoteFibra";

const PaginaContent = ({ pagina, onButtonClick, loadFluxo, isHomePage, starryCanvasRef }) => {
    const nodeRef = useRef(null);
    const navigate = useNavigate();
    const [fadeOutContent, setFadeOutContent] = useState(false);
    const [showNewContent, setShowNewContent] = useState(isHomePage);
    const [changelog, setChangelog] = useState({});
    const location = useLocation();
    const informacoesFluxoInicial = location.state?.informacoesFluxo || [];
    const [informacoesFluxo, setInformacoesFluxo] = useState(informacoesFluxoInicial);
    const midia = pagina.midia && pagina.midia.arquivo ? pagina.midia : null;
    // console.log(pagina);

    const triggerStarAnimation = (botao) => {
        // Fase 1: Esconder o título e os cards da página atual
        setFadeOutContent(true);
        document.body.classList.add('no-scroll');

        // Após o fade out do conteúdo atual, iniciamos a animação das estrelas
        const randomTx = getRandomInt(-20, 20);
        const randomTy = getRandomInt(-20, 20);
        let animationInterval = setInterval(() => {
            if (starryCanvasRef.current) {
                starryCanvasRef.current.changeVelocity({ tx: randomTx, ty: randomTy });
            }
        }, 100); // Atualizar a animação das estrelas a cada 100ms

        const callbackInterval = setInterval(() => {
            const novaInformacao = botao.informacao_fluxo;
            let novoState;

            if (novaInformacao && informacoesFluxo.indexOf(novaInformacao) == -1) {
                const botaoInformacaoNaPagina = pagina.cards[0].botoes.find(botao => informacoesFluxo.indexOf(botao.informacao_fluxo) != -1);

                if (botaoInformacaoNaPagina) {
                    const indexToRemove = informacoesFluxo.indexOf(botaoInformacaoNaPagina.informacao_fluxo);

                    const result = informacoesFluxo.filter((_, index) => index !== indexToRemove)
                    setInformacoesFluxo([...result, novaInformacao]);
                    novoState = { state: { informacoesFluxo: [result, novaInformacao] } };
                } else {
                    setInformacoesFluxo([...informacoesFluxo, novaInformacao]);
                    novoState = { state: { informacoesFluxo: [...informacoesFluxo, novaInformacao] } };
                }
            }

            if (loadFluxo) {
                // Se loadFluxo estiver definido, significa que estamos na HomePage
                loadFluxo(botao.link_para_fluxo_slug, botao.link_para_pagina_slug).then(() => {
                    navigate(`/fluxo/${botao.link_para_fluxo_slug}/${botao.link_para_pagina_slug}`, novoState);
                    stopAnimationAndShowContent();
                }).catch((error) => {
                    console.error("Erro ao carregar o fluxo: ", error);
                });
            } else if (onButtonClick) {
                // Se onButtonClick estiver definido, estamos na PaginaDetalhePage
                onButtonClick(botao.link_para_pagina_slug, novoState);
                stopAnimationAndShowContent();
            }
        }, 500);

        // Função para parar a animação das estrelas e mostrar o novo conteúdo
        const stopAnimationAndShowContent = () => {
            clearInterval(animationInterval); // Para a animação das estrelas
            clearInterval(callbackInterval);
            setFadeOutContent(false); // Mostrar o novo conteúdo
            setShowNewContent(true); // Mostrar a nova página
            document.body.classList.remove('no-scroll');
        };
    };

    useEffect(() => {
        if (!isHomePage) {
            setShowNewContent(true);
        }
    }, [isHomePage]);

    useEffect(() => {
        const getChangelog = async () => {
            const data = await fetchChangelog();
            setChangelog(data);
        };
        getChangelog();
    }, []);

    return (
        <div className="page-content">
            <Navbar titulo={pagina.titulo} isHomePage={isHomePage} informacoesFluxo={informacoesFluxo} />
            <CSSTransition in={!fadeOutContent && showNewContent} timeout={5000} classNames="fade" nodeRef={nodeRef} unmountOnExit>
                <div ref={nodeRef}>
                    { midia ? (
                        <div className="pagina-midia-layout">
                            <div className="midia-container">
                                {midia.tipo === 'imagem' && (
                                    <div className="midia-image">
                                        <img src={midia.arquivo} alt={midia.legenda || 'Imagem'} />
                                        {midia.legenda && <div className="midia-legenda" dangerouslySetInnerHTML={{ __html: midia.legenda_html }}/>}
                                    </div>
                                )}
                                {midia.tipo === 'video' && (
                                    <div className="midia-video">
                                        <video key={midia.arquivo} controls disablePictureInPicture  controlsList="nodownload noplaybackrate">
                                            <source src={midia.arquivo} type="video/mp4"></source>
                                        </video>
                                        {midia.legenda && <div className="midia-legenda" dangerouslySetInnerHTML={{ __html: midia.legenda_html }}/>}
                                    </div>
                                )}
                            </div>
                            <div className="informacoes-container">
                                <div className="pagina-procedimento">
                                    <div className="procedimento-texto" dangerouslySetInnerHTML={{ __html: midia.procedimento_html }} />
                                </div>

                                <Dica dica={midia.dica_html} piscando={midia.dica_piscando} />

                                <div className="pagina-card-container">
                                    {pagina.cards.map((card) => (
                                        <Card
                                            key={card.id}
                                            titulo={card.titulo}
                                            corDeFundo={card.cor_de_fundo}
                                            botoes={card.botoes}
                                            triggerStarAnimation={triggerStarAnimation}
                                            isMediaPage={true}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {pagina.cards.map((card) => (
                                <Card
                                    key={card.id}
                                    titulo={card.titulo}
                                    corDeFundo={card.cor_de_fundo}
                                    botoes={card.botoes}
                                    loadFluxo={loadFluxo}
                                    triggerStarAnimation={triggerStarAnimation}
                                    isMediaPage={false}
                                />
                            ))}
                        </div>
                    )}
                    <Footer titulo={changelog.titulo} copyright={changelog.copyright_html} />
                </div>
            </CSSTransition>
            <MascoteFibra informacoesFluxo={informacoesFluxo} pagina={pagina} />
        </div>
    );
};

export default PaginaContent;
